import React from 'react'
import { Link } from 'react-router-dom'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L, { icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import classes from '../../assets/css/stylebase.module.css'
import LogoPlatane from '../../assets/img/logo.svg'
import VKULogo from '../../assets/img/partner/favilogoBlackBg.png'
import deliverooImg from '../../assets/img/deliveroo.png'
import ubereatsImg from '../../assets/img/uber.png'
import googleMapsImg from '../../assets/img/google.png'
import snapchatLogo from '../../assets/img/snapchat.png'
import instaLogo from '../../assets/img/instagram.png'
import tiktokLogo from '../../assets/img/tik-tok.png'
import facebookLogo from '../../assets/img/facebook.png'
import googleIcon from '../../assets/img/playstore.png'
import appStoreIcon from '../../assets/img/app-store.png'
import iconMap from '../../assets/img/rEPRE.png'

const customIcon = new L.Icon({
  iconUrl: iconMap, // Înlocuiește cu calea către imaginea ta
  iconSize: [45, 57], // Setează dimensiunile iconului
  iconAnchor: [17, 35], // Punctul de ancorare pentru a poziționa corect iconul
  popupAnchor: [0, -35] // Punctul de ancorare pentru popup
});

const MyMap = () => {
  const position = [48.87, 2.12]
  const positionSannois = [48.97935277462922, 2.244348495727873];
  const positionTrappes = [48.77783123835872, 2.0059485887849404];

  return (
    <MapContainer center={position} zoom={9} style={{ height: '300px', width: '100%', borderRadius: "20px" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://vkucode.com" target="_blank">VKU CODE</a>'
      />
      <Marker position={positionSannois} icon={customIcon}>
        <Popup>
          Platane Pizza Sannois
        </Popup>
      </Marker>
      <Marker position={positionTrappes} icon={customIcon}>
        <Popup>
          Platane Pizza Trappes
        </Popup>
      </Marker>
    </MapContainer>
  );
};

const Footer = () => {
  

  return (
    <>
    <footer
      className={`${classes.siteFooter}`}
      id="footerPLTN"
      style={{borderRadius: "30px 30px 0px 0px"}}
    >
    <div className="container">
  <div className="row mt-4 mb-4">
    <div className="col-12 col-md-6">
      <h2 className="text-white mb-lg-0">
        <img src={LogoPlatane} alt="" width="60px" />&nbsp;Platane Pizza
      </h2>
    </div>
  </div>
  <div className="row">
    <div className="col-lg-4 col-12 mb-4 pb-2">
      <h5 className={`${classes.siteFooterTitle} mb-3`}>Liens</h5>

      <ul className={`${classes.siteFooterLinks}`}>
        <li className={`${classes.siteFooterLinkItem}`}>
          <a href="/" className={`${classes.siteFooterLink} text-footer`}>Accueil</a>
        </li>

        <li className={`${classes.siteFooterLinkItem}`}>
          <a href="/carte" className={`${classes.siteFooterLink} text-footer`}>Carte</a>
        </li>

        <li className={`${classes.siteFooterLinkItem}`}>
          <a href="/franchise" className={`${classes.siteFooterLink} text-footer`}>Franchise</a>
        </li>

        <li className={`${classes.siteFooterLinkItem}`}>
          <a href="/recrutement" className={`${classes.siteFooterLink} text-footer`}>Recrutement</a>
        </li>
        {/* <li className={`${classes.siteFooterLinkItem}`}>
          <Link to="/allergennes"
            target="_blank"
            className={`${classes.siteFooterLink} text-footer`}
            >Allergennes</Link>
        </li> */}
        <br /><br />
        <h5 className={`${classes.siteFooterTitle} mb-3`}>Reseaux sociaux</h5>
        <li className={`${classes.siteFooterLinkItem}`}>
          <a href="https://www.instagram.com/platanepizza/" className="fs-2"
            ><img src={instaLogo} alt="" width={"30px"} /></a>&nbsp;&nbsp;&nbsp;
          <a
            href="https://www.snapchat.com/add/platanepizza?invite_id=AyqcwI6m&locale=fr_FR&share_id=rXrHa5hISSe0xQ95SX1saQ&sid=47a1ba8f527c49d9b9510665262077fd"
            className="fs-2"
            ><img src={snapchatLogo} alt="" width={"30px"} /></a>&nbsp;&nbsp;&nbsp;
          <a
            className="fs-2"
            href="https://www.tiktok.com/@platanepizza?_t=8gOoXskiOum&_r=1"
            ><img src={tiktokLogo} alt="" width={"30px"} /></a>&nbsp;&nbsp;&nbsp;
            <a
            className="fs-2"
            href="https://www.facebook.com/platanepizza"
            ><img src={facebookLogo} alt="" width={"30px"} /></a>
        </li>
        <br /><br />
        <h5 className={`${classes.siteFooterTitle} mb-3`}>Applications mobiles</h5>
        <a
          className="fs-2"
          href="https://play.google.com/store/apps/details?id=com.softavera.platanepizza&pli=1"
          target="_blank"
          ><img src={googleIcon} alt="" width={"30px"} />
        </a>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a
          className="fs-2"
          href="https://apps.apple.com/fr/app/platane-pizza/id1545353983"
          ><img src={appStoreIcon} alt="" width={"30px"} /></a>
      </ul>
    </div>

    <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
      <h5 className={`${classes.siteFooterTitle} mb-3`}>Vous avez une question?</h5>

      <p className="text-white d-flex" style={{flexDirection: "column", gap: 3}}>
        <a
          href="mailto:contact@platanepizza.com"
          className={`${classes.siteFooterLink} text-footer`}>
          contact@platanepizza.com
        </a>
        <a
          href="/contact"
          className={`${classes.siteFooterLink} text-footer`}>
          Nous contacter
        </a>
      </p>
    </div>

    <div className="col-lg-4 col-md-6 col-11 mb-4 mb-lg-0 mb-md-0">
      <h5 className={`${classes.siteFooterTitle} mb-3`}>Localisation</h5>

      <p className={`text-white d-flex flex-column mt-3 mb-2 text-footer`}>
        32 Av. Paul Vaillant-Couturier, 78190 Trappes <br />
        <div style={{display: 'flex', flexDirection:'row', justifyContent: 'start', alignItems: 'center'}}>
        <a href="https://deliveroo.fr/fr/menu/Paris/trappes-elancourt-saint-quentin-en-yvelines/platane-pizza-trappes/?day=today&geohash=u09mmx99n32p&time=ASAP" className='fs-2' style={{color: '#03C7B7', padding: '0px 10px'}}><img src={deliverooImg} width={"100px"} /></a>
        <a href="https://www.ubereats.com/fr/store/platane-pizza-halal-trappes/0Ob1Xgn9S_eofAK7-FiGZw?diningMode=DELIVERY&sc=SEARCH_SUGGESTION" className='fs-2' style={{color: 'white', padding: '0px 10px'}}><img src={ubereatsImg} width={"100px"} /></a>
        <a href="https://maps.app.goo.gl/E88sn3UzzYpbt2ME9" className='fs-2' style={{textDecoration: 'none', color: 'white', padding: '0px 10px'}}><img src={googleMapsImg} width={"30px"} /></a>
        </div>
        
      </p>
      <p className={`text-white d-flex flex-column mt-3 mb-2 text-footer`}>
        19 Av. de la Sabernaude, 95110 Sannois <br />
        <div style={{display: 'flex', flexDirection:'row', justifyContent: 'start', alignItems: 'center'}}>
        <a href="https://deliveroo.fr/fr/menu/Paris/sannois-ermont-eaubonne/platane-pizza-sannois/?day=today&geohash=u09we74gss42&time=ASAP" className='fs-2' style={{color: '#03C7B7', padding: '0px 10px'}}><img src={deliverooImg} width={"100px"} /></a>
        <a href="https://www.ubereats.com/fr/store/platane-pizza-sannois/bi4IOc-6XyeohNHpGLXK7w?diningMode=DELIVERY&sc=SEARCH_SUGGESTION" className='fs-2' style={{color: 'white', padding: '0px 10px'}}><img src={ubereatsImg} width={"100px"} /></a>
        <a href="https://maps.app.goo.gl/8RKSFhpnfEZpqSMX7" className='fs-2' style={{textDecoration: 'none', color: 'white', padding: '0px 10px'}}><img src={googleMapsImg} width={"30px"} /></a>
        </div>
      </p>

      <p
        className={`${classes.linkFx1} color-contrast-higher mt-3`}
      >
        <span>Nos Restos</span>
        
      </p>
      <div>
      <MyMap />
      </div>
    </div>
  </div>
</div>

<div className={`${classes.siteFooterBottom}`}>
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-12 mt-lg-12">
        <ul className={`${classes.siteFooterLinks} text-center`}>
          <li className={`${classes.siteFooterLinkItem}`}>
            <a href="https://platanepizza.fr/legales" className={`${classes.siteFooterLink} mt-3`}
              >Mentions Legales</a>
          </li>
        </ul>
      </div>
      <div className="col-lg-12 col-12 mt-12">
        <p className={`${classes.copyrightText} text-center`}>
          Copyright © 2024 Platane Pizza | Powered by&nbsp;<a href="https://vkucode.com" target='_blank'><img src={VKULogo} alt="VKU CODE" width={"40px"} /></a>
        </p>
      </div>
    </div>
  </div>
</div>
</footer>
</>
  )
}

export default Footer;