import React, { useState } from 'react'
import axios from 'axios';
import '../assets/css/recrutement.css'
import '../assets/css/accueil.css'
import { FaPaperPlane } from 'react-icons/fa6';

const Recrutement = () => {
  var formSubmitted = false;
  const [responseHTML, setResponseHTML] = useState("")
  let repsonseELement = document.getElementById("responseContainer");
  
  function checkForm() {
    let nom = document.getElementById("firstname").value;
    let prenom = document.getElementById("lastname").value;
    let email = document.getElementById("email").value;
    let tel = document.getElementById("tel").value;
    let message = document.getElementById("message").value;
    let cv = document.getElementById("cv").files[0]; // Acces la fișier
    let motivation_letter = document.getElementById("motivation_letter").files[0]; // Acces la fișier

    if (!nom || !prenom || !email || !tel) {
      alert("Veuillez remplir tous les champs du formulaire.");
      return false;
    } else {
      if (!formSubmitted) {
        submitForm();
      }
      return true;
    }
  }

  function submitForm() {
    var formData = new FormData();
    formData.append('firstname', document.getElementById("firstname").value);
    formData.append('lastname', document.getElementById("lastname").value);
    formData.append('email', document.getElementById("email").value);
    formData.append('tel', document.getElementById("tel").value);
    formData.append('message', document.getElementById("message").value);
    formData.append('cv', document.getElementById("cv").files[0]); // Adaugă fișierul
    formData.append('motivation_letter', document.getElementById("motivation_letter").files[0]); // Adaugă fișierul

    axios.post('https://www.platanepizza.fr/sendMessageJob.php', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(function (response) {
      // Gestionați răspunsul de succes aici
      console.log(response.data);
      formSubmitted = true;
      setResponseHTML(response.data);

      // Opțional: Afișează un mesaj de succes sau actualizează UI
    })
    .catch(function (error) {
      // Gestionați eroarea aici
      console.log(error);
    });
  }

  return (
    <>
    <main>
    <nav className="navbar navbar-expand-lg bg-black" id="navbarPLTN"></nav>
    <section id="headerTopForms">
      <div className="container-fluid">
        <div className="row d-flex flex-row justify-content-center align-items-center">
          <div className="col-12 col-md-10 col-lg-8">
            <h2 className="headerTopText">
              Alors <br />
              prêt à rejoindre le mouvement? <br />
            </h2>
          </div>
        </div>
      </div>
    </section>
    <section id="jobContainerForm" className="formPlataneContainer scroll-reveal">
      <div className="container">
        <div className="row d-flex flex-row justify-content-center align-items-center">
          <div className="col-12 col-md-10 col-lg-8 d-flex justify-content-center">
            <form id="job-form" action="" method="POST" encType="multipart/form-data">
              <h3 className="tittlePage">Remplissez ce formulaire</h3>
              <input type="text" name="firstname" id='firstname' className="mt-2 mb-2" placeholder="Nom*" required />
              <br />
              <input type="text" name="lastname" id='lastname' className="mt-2 mb-2" placeholder="Prenom*" required />
              <br />
              <input type="email" name="email" id='email' className="mt-2 mb-2 " placeholder="Email*" required />
              <br />
              <input type="tel" name="tel" id='tel' className="mt-2 mb-2 " placeholder="Telephone*" required />
              <br />
              <p className="tittlePage mt-3 ">CV (PDF)</p>
              <input type="file" id='cv' name="cv" accept=".pdf" />
              <p className="tittlePage mt-3 ">Lettre de motivation (PDF)</p>
              <input type="file" id='motivation_letter' name="motivation_letter" accept=".pdf" />
              <br /><br />
              <textarea type="text" name="message" id='message' className="mt-2 mb-2 " placeholder="Message"  />
              <br />
              <div id="responseContainer" className='text-dark'>{responseHTML}</div>
              <button type="button" className="nxtBTN mt-3 mb-2 " onClick={checkForm}>
                Envoyer&nbsp;<FaPaperPlane />
              </button>
              
            </form>



          </div>
        </div>
      </div>
    </section>
  </main>
  </>
  )
}

export default Recrutement