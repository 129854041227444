import React, { useState, useEffect } from 'react';
import { products } from '../data/data';
import classes from '../assets/css/carte.module.css';
import '../assets/css/accueil.css'
import 'react-bootstrap/dist/react-bootstrap'
import photo1 from '../assets/img/carte/Photo_1.png'
import photo2 from '../assets/img/carte/Photo_2.png'
import photo3 from '../assets/img/carte/Photo_3.png'
import photo4 from '../assets/img/carte/Photo_4.png'
import 'animate.css'
import appleStore from '../assets/img/Slides/Bouton_App.png'
import googleStore from '../assets/img/Slides/Bouton_Google.png'
import handPhone from '../assets/img/Slides/Hand.png'
import notreAppli from '../assets/img/Slides/Rectangle_Rouge.png'
// import Slide_1 from '../assets/img/Slides/Slide_Calzon.jpg'
// import Slide_2 from '../assets/img/Slides/Slide_Gratiné.jpg'
// import Slide_3 from '../assets/img/Slides/Slide_Pizza.jpg'


const Carte = () => {
// State-ul pentru categoria selectată și produsele filtrate
const [activeType, setActiveType] = useState('Pizza');
const [filteredProducts, setFilteredProducts] = useState([]);
// const images = [Slide_1, Slide_2, Slide_3];
// const [currentImage, setCurrentImage] = useState(1);
const [fadeInClass, setFadeInClass] = useState("animate__fadeIn")


// Functia pentru a crea un slide Show
// useEffect(() => {
//   const interval = setInterval(() => {
//     setCurrentImage(currentImage => {
//       const nextIndex = (currentImage + 1) % images.length;
//       console.log("Changing image to index: ", nextIndex); // Pentru debugging
//       setFadeInClass("animate__fadeIn");
//       return nextIndex;
//     });
//   }, 4000);

//   return () => clearInterval(interval);
// }, []);

// const backgroundImage = images[currentImage];

// Actualizăm produsele filtrate atunci când se schimbă categoria activă
useEffect(() => {

  setFilteredProducts(products.filter(product => product.type === activeType));
}, [activeType]);

// Funcția care schimbă categoria activă
const handleTypeClick = (type) => {
  setActiveType(type);
};


  return (
    <>
    <section 
        className={`${classes.headerTopCarte} animate__animated ${fadeInClass}`} id='header'
        // style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className={`${classes.imgCartePremiere} container-fluid`}  >
          <div className='row'>
            <div className='col-12 d-flex flex-row justify-content-center align-items-center'>
              <div className={`${classes.textContent}`}>
                <h1>Telecharger</h1>
                <h2><img src={notreAppli} alt="" /></h2>
                <div className='d-flex flex-row justify-content-center align-items-center text-center'>
                <a href="https://apps.apple.com/fr/app/platane-pizza/id1545353983" target='_blank'><img src={appleStore} alt=""/></a>
                <a href="https://play.google.com/store/apps/details?id=com.softavera.platanepizza&pli=1" target='_blank'><img src={googleStore} alt=""/></a>
                </div>
              </div>

            <div className={`${classes.imageContent}`}>
              <img src={handPhone} className={`${classes.handImage}`} alt="" />
            </div>
            </div>
            
          </div>
        </div>
      </section>
      <section>
        <div className="container mb-5">
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <br />
            <h2><strong>Notre carte</strong></h2>
              <p className="textDescriptionFra scroll-reveal">
                <b>Chez Platane Pizza,</b> nous croyons fermement que la qualite
                est la cle du succes. Nous selectionnons avec soin les meilleurs
                ingredients, des produits authentiques, pour vous offrir une
                experience gustative inoubliable.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-md-3 scroll-reveal">
              <img
                src={photo1}
                className={`${classes.imgProdCarte}`}
                alt=""
                width="100%"
              />
            </div>
            <div className="col-6 col-md-3 scroll-reveal">
              <img
                src={photo2}
                alt=""
                width="100%"
                className={`${classes.imgProdCarte}`}
              />
            </div>
            <div className="col-6 col-md-3 scroll-reveal">
              <img
                src={photo3}
                alt=""
                width="100%"
                className={`${classes.imgProdCarte}`}
              />
            </div>
            <div className="col-6 col-md-3 scroll-reveal">
              <img
                src={photo4}
                alt=""
                width="100%"
                className={`${classes.imgProdCarte}`}
              />
            </div>
          </div>

          <div className="row mt-5 mb-5">
            <div className="col-12 text-center d-flex justify-content-center align-items-center">
              <div className={`${classes.productsNavBar}`}>
                <button
                    onClick={() => handleTypeClick('Pizza')}
                    className={`${classes.productsNavLink} ${activeType === 'Pizza' ? classes.active : ''}`}
                  >Pizza</button>
                <button
                    onClick={() => handleTypeClick('Signature')}
                    className={`${classes.productsNavLink} ${activeType === 'Signature' ? classes.active : ''}`}
                  >Signature</button>
                <button
                    onClick={() => handleTypeClick('Calzone')}
                    className={`${classes.productsNavLink} ${activeType === 'Calzone' ? classes.active : ''}`}
                  >Calzone</button>  
                <button
                    onClick={() => handleTypeClick('Salade')}
                    className={`${classes.productsNavLink} ${activeType === 'Salade' ? classes.active : ''}`}
                  >Salade</button>  
                <button
                    onClick={() => handleTypeClick('Gratin')}
                    className={`${classes.productsNavLink} ${activeType === 'Gratin' ? classes.active : ''}`}
                  >Gratin</button>   
                <button
                    onClick={() => handleTypeClick('Dessert')}
                    className={`${classes.productsNavLink} ${activeType === 'Dessert' ? classes.active : ''}`}
                  >Dessert</button>  
                  <button
                    onClick={() => handleTypeClick('TexMex')}
                    className={`${classes.productsNavLink} ${activeType === 'TexMex' ? classes.active : ''}`}
                  >Tex Mex</button>  
                  <button
                    onClick={() => handleTypeClick('Junior')}
                    className={`${classes.productsNavLink} ${activeType === 'Junior' ? classes.active : ''}`}
                  >Junior</button>  
              </div>
            </div>
            <div className={`${classes.productsContent} col-12 mt-4`}>
                {filteredProducts.map((product) => (
                  <div key={product.id} className={`${classes.productCard} col-4 col-lg-3`}>
                  <div className={`${classes.imgContainer}`}>
                    <div className={`${classes.overlayFilter}`}></div>
                    <img src={product.img} alt={product.name} />
                  </div>
                    <span className='d-none'>{product.type}</span>
                    <span>{product.name}</span>
                  </div>
                ))}
           </div>
          </div>
        </div>
      </section>
      </>
  )
}

export default Carte