import { Routes, Route } from "react-router-dom";
import Accueil from "./components/Accueil";
import Legales from "./components/Legales";
import Carte from "./components/Carte";
import Franchise from "./components/Franchise";
import Recrutement from "./components/Recrutement";
import Contact from "./components/Contact";
import Footer from "./components/elements/Footer";
import "./assets/css/bootstrap.min.css";
import "./assets/css/bootstrap-icons.css";
import NewNavbar from "./components/elements/NewNavbar";

function App() {
  return (
    <div>
      <NewNavbar />
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/accueil" element={<Accueil />} />
        <Route path="/carte" element={<Carte />} />
        <Route path="/franchise" element={<Franchise />} />
        <Route path="/recrutement" element={<Recrutement />} />
        <Route path="/legales" element={<Legales />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
