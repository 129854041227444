import React from 'react'
import { Link } from 'react-router-dom'
import classes from '../../assets/css/navbar.module.css'
import {FaPizzaSlice} from 'react-icons/fa6'
import LogoPlatane from '../../assets/img/logo.svg'
import 'react-bootstrap/dist/react-bootstrap'


const NewNavbar = () => {
  return (
    <nav className={`${classes.navbarPage} navbar navbar-expand-lg navbar-light bg-black`}>
          <div className="container">
            <Link className={`${classes.navbarBrand}`} to="/"><img src={LogoPlatane} alt="" width={"50px"} />&nbsp;<span>Platane Pizza</span></Link>
            <a
            href="https://www.platanepizza.com/"
            className={`btn ${classes.customBtn} d-lg-none ms-auto me-4`}>Commander&nbsp;<FaPizzaSlice></FaPizzaSlice>
            </a>
            <button className={`${classes.navbarToggler} navbar-toggler d-lg-none`} type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavId" aria-controls="collapsibleNavId"
                aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavId">
                <ul className="navbar-nav m-auto mt-2 mt-lg-0">
                    <li className="nav-item">
                        <a className={`${classes.navLink} nav-link`} href="https://platanepizza.fr/">Accueil</a>
                    </li>
                    <li className="nav-item">
                        <a className={`${classes.navLink} nav-link`} href='https://platanepizza.fr/carte' >Notre Carte</a>
                    </li>
                    <li className="nav-item">
                        <a className={`${classes.navLink} nav-link`} href="https://platanepizza.fr/franchise">Franchise</a>
                    </li>
                    <li className="nav-item">
                        <a className={`${classes.navLink} nav-link`} href="https://platanepizza.fr/recrutement">Recrutement</a>
                    </li>
                </ul>
                <a
                href="https://www.platanepizza.com/"
                className={`btn ${classes.customBtn} d-lg-block d-none`}>Commander&nbsp;<FaPizzaSlice></FaPizzaSlice>
                </a>

            </div>

      </div>
    </nav>

    
    
  )
}

export default NewNavbar