import React, {useState, useEffect} from 'react'
import video from '../assets/video/reelNouveautes.mp4'
import videoPoster from '../assets/img/Photo_2.png'
import '../assets/css/accueil.css'
import {FaInstagram, FaSnapchat, FaTiktok, FaLocationPin} from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import imgBienevenue from '../assets/img/Bienvenue.png'
import imgEnSavoirPlus from '../assets/img/Rectangle_Ensavoirplus.png'
import imgPizza from '../assets/img/PIzza.png'
import imgNotreCarte from '../assets/img/Notre_Carte.png'
import imgVoirNotreCarte from '../assets/img/Rectangle_VoirNotreCarte.png'
import imgPizza1 from '../assets/img/imgProd/Photo_Pizza.png' 
import imgPizza2 from '../assets/img/imgProd/Photo_Gratin.png'
import imgPizza3 from '../assets/img/imgProd/Photo_Milkshake.png'
import imgTextPostulez from '../assets/img/postulez chez nous.png'
import imgRecPostulez from '../assets/img/Rectangle_PostuléIci.png'
import imgBoutique from '../assets/img/imgProd/Photo_Boutique.png'
import imgTShirt from '../assets/img/imgProd/Photo_Tshirt.png'
import imgLogoBig from '../assets/img/Logo_Big.png'
import snapchatLogo from '../assets/img/snapchat.png'
import instaLogo from '../assets/img/instagram.png'
import tiktokLogo from '../assets/img/tik-tok.png'
import facebookLogo from '../assets/img/facebook.png'

const Accueil = () => {

  // Stiluri care depind de lățimea ferestrei
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const stickerVideoStyle = { opacity: screenWidth < 768 ? '0' : '1' };
  const sectionOverlayStyle = { opacity: screenWidth < 768 ? '0' : '0.3' };

  // Manevrarea schimbărilor dimensiunii ferestrei
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    
    // Verificați lățimea ecranului la încărcarea componentei
    handleResize();

    // Curățați listener-ul când componenta se demontează
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Vă rugăm să actualizați sursele video conform necesităților
  const videoSource = screenWidth < 768 ? video : video;

  return (
    <>
    <main>
     <section className="hero-section" id="section_1">
          <div className="section-overlay" style={sectionOverlayStyle}></div>

        <div className="container d-flex justify-content-center align-items-center">
          <div className="row">
            <div className="col-12 mt-auto text-center mb-5">

            </div>

            <div
              className="textHeroSection col-12 mt-auto flex-column flex-lg-row "
            >
              <div className="date-wrap">
                <h5 className="text-white">
                  <a href="https://www.instagram.com/platanepizza/" className="fs-2"
                    ><img src={instaLogo} alt="" width={"40px"} /></a>&nbsp;&nbsp;
                  <a
                    href="https://www.snapchat.com/add/platanepizza?invite_id=AyqcwI6m&locale=fr_FR&share_id=rXrHa5hISSe0xQ95SX1saQ&sid=47a1ba8f527c49d9b9510665262077fd"
                    className="fs-2"
                    ><img src={snapchatLogo} alt="" width={"40px"} /></a>&nbsp;&nbsp;
                  <a
                    className="fs-2"
                    href="https://www.tiktok.com/@platanepizza?_t=8gOoXskiOum&_r=1"
                    ><img src={tiktokLogo} alt="" width={"40px"} /></a>&nbsp;&nbsp;
                    <a
                    className="fs-2"
                    href="https://www.facebook.com/platanepizza"
                    ><img src={facebookLogo} alt="" width={"40px"} /></a>
                </h5>
              </div>

              <div className="location-wrap mx-auto py-3 py-lg-0">
                <h5 className="text-white">
                  <a
                    href="https://www.google.com/maps/search/Platane+Pizza"
                    className="heroLink text-white"
                    target="_blank"
                    ><FaLocationPin style={{color: "#fff"}}></FaLocationPin>
                    Voir nos restos</a>
                </h5>
              </div>

              <div className="social-share">
                <h5 className="text-white">
                  <Link to="/Carte" className="heroLink text-white">
                    <i className="fa-solid fa-pizza-slice" style={{color: "#fff"}}></i>
                    Notre Carte
                  </Link>
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="video-wrap">
            <video
              autoPlay
              loop
              muted
              className="custom-video"
              poster={videoPoster}
            >
              <source src={videoSource} type="video/mp4" />
            </video>
          </div>
      </section>

      <section className="about-section section-padding" id="">
        <div className="container">
          <div
            className="row d-flex flex-column text-center justify-content-center align-items-center"
          >
            <div className="col-12 text-center">
              <img
                src={imgBienevenue}
                alt=""
                width="100%"
                className="imgBienVenue scroll-reveal"
              />
            </div>
            <div className="col-12 col-md-11 col-lg-10">
              <p className="textDescription scroll-reveal">
                <b>L'endroit incontournable</b> pour les amateurs de
                restauration rapide de qualité ! Plonge dans l'univers envoûtant
                de notre concept inspiré de la street food américaine. Chez
                Platane Pizza, la pizza est la star indétrônable de notre menu !
                Découvre nos délicieuses créations aux inspirations américaines
                qui te feront saliver. Que ce soit lors de moments en famille ou
                entre amis, nos pizzas sauront satisfaire tes papilles et
                accompagner ces instants conviviaux. Alors, ne perds pas une
                minute et viens vivre une expérience culinaire exceptionnelle
                chez Platane Pizza !
              </p>
              <Link to="/Carte"
                ><img
                  src={imgEnSavoirPlus}
                  alt=""
                  className="ensvPlusIMG scroll-reveal"
              /></Link>
            </div>
          </div>
        </div>
      </section>

      <section className="franchise-section section-padding" id="">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-2 col-md-2 col-12">
              <img
                src={imgPizza}
                alt=""
                width="1px"
                className="pizzaIMGFranchise scroll-reveal"
              />
            </div>
            <div className="col-lg-10 col-md-10 col-12 text-center">
              <h2>Notre Franchise</h2>
              <p className="textDescriptionFra scroll-reveal">
                <b>Là où la restauration rapide</b> prend une toute nouvelle
                dimension. Inspirés par la street food américaine, nous avons
                créé un concept qui allie rapidité, saveurs US et choix de
                recettes à tomber par terre. Chez Platane Pizza, notre priorité
                est de vous offrir une expérience culinaire exceptionnelle, sans
                compromis sur la qualité.
              </p>
              <p className="textDescriptionFra scroll-reveal">
                <b>Si vous êtes passionné</b> par une restauration de qualité et
                que vous rêvez de vous lancer dans une aventure excitante et
                lucrative, alors rejoignez-nous chez Platane Pizza et devenez un
                partenaire privilégié de notre succès florissant.
              </p>
              <br /><br />
              <Link href="/franchise"
                ><img
                  src={imgEnSavoirPlus}
                  alt=""
                  className="ensvPlusIMG scroll-reveal"
              /></Link>
            </div>
          </div>
        </div>
      </section>

      <section className="notre-carte section-padding scroll-reveal" id="">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 text-center">
              <img
                src={imgNotreCarte}
                alt=""
                className="imgProdCarte scroll-reveal"
              />
            </div>
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <p className="textDescription scroll-reveal">
                Conçu pour séduire toutes les generations avec un concept
                innovant et audacieux. Laissez-vous emporter par les saveurs
                vibrantes de la street food américaine à chaque délicieuse
                bouchée. Notre carte, élaborée avec passion, regorge de
                créations alléchantes qui combleront toutes vos envies les plus
                exquises. Préparez-vous à succomber à la tentation avec nos
                pizzas aux noms inspirés des grandes villes américaines, nos mac
                & cheeses irrésistibles, nos paninis généreux et nos entrées
                appétissantes qui éveilleront vos papilles dès la première
                bouchée.
              </p>
              <Link to="/carte">
                <img
                  src={imgVoirNotreCarte}
                  alt=""
                  className="ensvPlusIMG scroll-reveal"
                />
              </Link>
            </div>
            <div className="col-12">
              <div
                className="row justify-content-center align-items-center text-center"
              >
                <div className="col-12 col-md-4">
                  <img
                    src={imgPizza1}
                    alt=""
                    className="imgProdCarte scroll-reveal"
                  />
                </div>
                <div className="col-12 col-md-4">
                  <img
                    src={imgPizza2}
                    alt=""
                    className="imgProdCarte scroll-reveal"
                  />
                </div>
                <div className="col-12 col-md-4">
                  <img
                    src={imgPizza3}
                    alt=""
                    className="imgProdCarte scroll-reveal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="job-section section-padding section-bg" id="">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 text-center upperBlockPostulez">
              <img
                src={imgTextPostulez}
                alt=""
                className="imgProdCarte scroll-reveal"
              />
            </div>
            <div
              className="col-12 col-md-10 col-lg-8 text-center upperBlockPostulez"
            >
              <p className="textDescriptionFra scroll-reveal mb-5">
                <b>Alors, prêt à rejoindre le mouvement</b> Platane Pizza ? Ne
                manquez pas cette occasion de devenvir un acteur clé de notre
                succès grandissant. Relevez le défi, écrivez votre propre
                histoire de réussite et participez à la construction d'une
                marque qui fait vibrer les papilles et réinvente la restauration
                rapide.
              </p>
              <Link to="/recrutement">
                <img
                  src={imgRecPostulez}
                  alt=""
                  className="ensvPlusIMG scroll-reveal"
                />
              </Link>
            </div>
            <div className="row">
              <div className="col-12 col-md-6" id="imgPostulezBlock">
                <img
                  src={imgBoutique}
                  className="scroll-reveal"
                  alt=""
                />
                <img
                  src={imgTShirt}
                  className="scroll-reveal"
                  alt=""
                />
              </div>
              <div
                className="col-12 col-md-6 d-flex justify-content-end align-items-end"
                id="logoBigBlock"
              >
                <img src={imgLogoBig} className="scroll-reveal" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    </>
  )
}

export default Accueil